<template>
  <FormCard
    :title="productTitle"
    :status="product.status"
    @save="validate"
    @cancel="$router.push({ name: 'products' })"
  >
    <v-form
      v-if="currentTabs"
      class="px-5 mt-5"
      ref="form"
    >
      <v-tabs v-model="currentTab">
        <v-tabs-slider color="primary" />

        <v-tab
          v-for="item in currentTabs"
          :key="item.title"
        >
          {{ item.title }}
        </v-tab>

        <v-tabs-items v-model="currentTab">
          <v-tab-item
            v-for="item in currentTabs"
            :key="item.title"
            class="py-10"
          >
            <component
              :product="product"
              :is="item.component"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  props: {
    valid: Boolean,
    product: {
      type: Object,
      required: true,
    },
    composed: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormCard: () => import('@/components/FormCard'),
    MainData: () => import('./form-sections/MainData'),
    PrintData: () => import('./form-sections/PrintData'),
    Media: () => import('./form-sections/Media'),
    Vendors: () => import('./form-sections/Vendors'),
    Categories: () => import('./form-sections/Categories'),
    Composition: () => import('./form-sections/Composition'),
  },
  data: () => ({
    currentTab: null,
    currentTabs: [],
    tabsItems: [
      { title: 'Dados Principais', component: 'main-data' },
      { title: 'Gravação', component: 'print-data' },
      { title: 'Mídias', component: 'media' },
      { title: 'Categorias', component: 'categories' },
      { title: 'Fornecedores', component: 'vendors' },
    ],
    tabsItemsComposed: [
      { title: 'Dados Principais', component: 'main-data' },
      { title: 'Gravação', component: 'print-data' },
      { title: 'Mídias', component: 'media' },
      { title: 'Categorias', component: 'categories' },
      { title: 'Composição', component: 'composition' },
    ],
    profile: null,
  }),
  computed: {
    isValid: {
      get: function () {
        return this.valid
      },
      set: function (value) {
        this.$emit('validate', value)
      },
    },
    productTitle() {
      if (this.product.product_reference) {
        return (
          this.product.product_reference + ' - ' + this.product.product_name
        )
      } else if (this.product.id) {
        return this.product.id + ' - ' + this.product.product_name
      }

      return 'Novo Produto'
    },
  },
  methods: {
    validate() {
      // let isValid = this.$refs.form.validate()
      this.$emit('save')
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
  mounted() {
    console.log(this.composed)
    if (this.composed) {
      this.currentTabs = this.tabsItemsComposed
      this.product.is_composition = true
      console.log('É composto: ', this.currentTabs)
    } else {
      this.currentTabs = this.tabsItems
      this.product.is_composition = false
      console.log('NÃO é composto: ', this.currentTabs)
    }
  },
}
</script>